import React, { ChangeEvent, useState } from 'react';
import styled from "styled-components"
import AppMarkDown from './AppMarkdown';


const Content = styled.div`
    display: flex;
    height: calc(100vh - 60px);
`

const LeftWrapper = styled.div`

    display: flex;
    flex-direction: column;
    width: 200px;
    border-right: 1px solid #ccc;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    .row{
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-bottom: 10px;
        span{
          width: 40px;
          height: 40px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
        button{
            cursor: pointer;
        }
    }
`
const RightWrapper = styled.div`
    display: flex;
    flex:1;
    flex-direction: column;
    padding: 30px;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    p {
      text-align: left;
    }
    hr{
      width: 100%;
    }
`

const StyleUserInfo = styled.div`
	margin-left:200px;
	display: flex;
	gap: 20px;
    .input-wrapper{
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
        .input-box{
            width: 100%;
            height: 48px;
            padding: 8px 12px;
            border: 1px solid #80808069;
            display: flex;
            border-radius: 12px;
            position: relative;
            align-items: center;
            input {
                flex: 1;
                cursor: pointer;
                background: none;
                padding: 0;
                font-size: 16px;
                outline: none;
                border: none;
            }
        }
    }
`

export interface IUser {
	email: string;
	taskId: string;
}

const JsonlUploader: React.FC = () => {
	const [data, setData] = useState<string[] | null>(null);
	const [currentJson, setCurrentJson] = useState<string | null>(null);
	const [currentIndex, setCurrentIndex] = useState<number | null>(null);
	const [userInfo, setUserInfo] = useState<IUser>({ email: '', taskId: '' });

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		setCurrentJson(null);
		setCurrentIndex(null);
		const file = event.target.files && event.target.files[0];
		if (!file) return;

		const reader = new FileReader();

		reader.onload = function (e: any) {
			try {
				const lines = e.target.result?.toString().split('\n');
				if (!lines) return;
				const jsonlData = lines.map((line: string) => {
					return line;
				});
				setData(jsonlData);
			} catch (error) {
				console.log(error);
			}
		};

		reader.readAsText(file);
	};

	return (
		<div>
			<div className='container'>
				<div className="button-wrap">
					<label className="button" htmlFor="upload">Upload File</label>
					<input id="upload" type="file" accept='.jsonl' onChange={handleFileChange} />
				</div>
				<StyleUserInfo>
					<div className="input-wrapper">
						<span>Email</span>
						<div className="input-box">
							<input type="text" value={userInfo.email} onChange={(e) => {
								const newUserInfo = { ...userInfo };
								newUserInfo.email = e.target.value;
								setUserInfo(newUserInfo);
							}} />
						</div>
					</div>
					<div className="input-wrapper">
						<span>TaskId</span>
						<div className="input-box">
							<input type="text" value={userInfo.taskId} onChange={(e) => {
								const newUserInfo = { ...userInfo };
								newUserInfo.taskId = e.target.value;
								setUserInfo(newUserInfo);
							}} />
						</div>
					</div>					
				</StyleUserInfo>
			</div>
			<Content>
				<LeftWrapper>
					{data && data.map((item: string, index: number) => {
						return <div key={index} className='row'>
							<span style={{ backgroundColor: index == currentIndex ? '#00800099' : '#8080807d' }}>{index + 1}</span>
							<button onClick={() => {
								setCurrentJson(item);
								setCurrentIndex(index);
							}}>查看</button>
						</div>
					})}
				</LeftWrapper>
				<RightWrapper>
					{
						currentJson && <AppMarkDown markDownData={currentJson} userInfo={userInfo} questionIndex={currentIndex ?? -1} />
					}
				</RightWrapper>
			</Content>
		</div>
	);
}

export default JsonlUploader;