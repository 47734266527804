import { child, get, getDatabase, ref, set } from "firebase/database";
import { useEffect, useState } from "react";
import { styled } from "styled-components";
import AutoResizeTextarea from "./AutoResizeTextarea";
import { IUser } from "./jsonlUpload";
import MarkButton from "./markButton";


const StyleSubmitMark = styled.div`
    display: flex;
    gap: 60px;
    .step-box{
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 20px;
        .step{
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
                border: 1px solid #80808069;
                border-radius: 20px;
                padding: 10px;
                position: relative;
                .delete{
                    position: absolute;
                    top: -15px;
                    right: -6px;
                    width: 40px;
                    height: 40px;
                    background-color: #808080e0;
                    color: white;
                    border-radius: 100%;
                    font-size: 17px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    &:hover{
                        background-color: red;
                    }
                }
                .judge{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 10px;
                    span{
                        width: 100px;
                        text-align: left;
                    }
                    select{
                        background-color: #fff;
                        padding: 10px;
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        outline: none;
                        cursor: pointer;
                        flex: 1;
                    }
                }
                .input-wrapper{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 10px;
                    width: 100%;
                    span{
                        width: 100px;
                        text-align: left;
                    }
                    textarea{
                        width: 100%;
                        border: 1px solid rgba(128, 128, 128, 0.41);
                        border-radius: 12px;
                        padding: 20px 10px;
                        outline: none;
                    }
                    .input-box{
                        flex: 1;
                        height: 48px;
                        padding: 8px 12px;
                        border: 1px solid #80808069;
                        display: flex;
                        border-radius: 12px;
                        position: relative;
                        align-items: center;
                        width: 100%;
                        input {
                            flex: 1;
                            cursor: pointer;
                            background: none;
                            padding: 0;
                            font-size: 16px;
                            outline: none;
                            border: none;
                        }
                    }
                }
            }
    }
    .action{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 20px;
        button{
            background: linear-gradient(135deg, #6e8efb, #a777e3);
            height: 48px;
            width: 200px;
            border: none;
            border-radius: 12px;
            color: #fff;
            cursor: pointer;
        }
    }
`

interface ISubmitMark {
    text: string;
    label: number;
    comment: string;
}

const defaultMarkData: ISubmitMark = {
    text: '',
    label: 0,
    comment: ''
}

interface ISubmitMarkProps {
    userInfo: IUser;
    questionIndex: number;
    originJson: any;
}

const SubmitMark = (props: ISubmitMarkProps) => {

    const {userInfo, questionIndex, originJson } = props;

    const [showSubmitLoading, setShowSubmitLoading] = useState<boolean>(false);

    const queryMarkData = () => {
        if (!!userInfo.taskId) {
            const dbRef = ref(getDatabase());
            get(child(dbRef, `marks/${userInfo.taskId}/${questionIndex}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    const queryData = snapshot.val();
                    setMarkData(queryData['markData']);
                } else {
                    setMarkData([]);
                }
            }).catch((error) => {
                console.error(error);
                setMarkData([]);
            });
        } else {
            setMarkData([]);
        }
    }

    useEffect(() => {
        queryMarkData();
    }, [questionIndex])

    const [markData, setMarkData] = useState<ISubmitMark[]>([]);

    const autoHandleStep = () => {
        const stepArr = originJson['answer'].split('\n\n');
        const tempStepArr:ISubmitMark[] = [];
        for (let index = 0; index < stepArr.length; index++) {
            tempStepArr.push({text: stepArr[index], label: 0, comment: ''})
        }
        setMarkData(tempStepArr);
    }

    const submit = () => {
        if (!userInfo.email) return alert('请先输入邮箱');
        if (!userInfo.taskId) return alert('请先输入任务Id');
        if (markData.length == 0) return alert('请先添加标注步骤');
        setShowSubmitLoading(true);
        const db = getDatabase();
        const saveDataInfo = {
            'authorEmail': userInfo.email,
            'originJson': originJson,
            'markData': markData
        }
        set(ref(db, 'marks/' + `${userInfo.taskId}/` + questionIndex), saveDataInfo)
            .then(() => {
                alert("数据成功写入数据库");
                setShowSubmitLoading(false);
            }).catch((error) => {
                alert('写入数据时发生错误');
                setShowSubmitLoading(false);
            });
    }

    const markLabelChange = (index: number, labelValue: number) => {
        const newMarkData = [...markData];
        newMarkData[index].label = labelValue;
        setMarkData(newMarkData);
    }

    return (
        <StyleSubmitMark>
            <section className="step-box">
                {
                    markData.map((item, index) => (
                        <div className="step" key={index}>
                            <div className="delete" onClick={() => {
                                const newMarkData = [...markData];
                                newMarkData.splice(index, 1);
                                setMarkData(newMarkData);
                            }}>X</div>
                            <div className="input-wrapper">
                                <span>Text</span>
                                <AutoResizeTextarea text={item.text} onChange={(value) => {
                                    const newMarkData = [...markData];
                                    newMarkData[index].text = value;
                                    setMarkData(newMarkData);
                                }}></AutoResizeTextarea>
                            </div>
                            <div className="judge">
                                <span>Label</span>
                                <div className="custom-select">
                                    <MarkButton label="Good" status="good" isSelected={item.label == 1} onClick={() => {
                                        markLabelChange(index, 1);
                                    }} />
                                    <MarkButton label="Neutral" status="neutral" isSelected={item.label == 0}  onClick={() => {
                                        markLabelChange(index, 0);
                                    }} />
                                    <MarkButton label="Bad" status="bad" isSelected={item.label == -1} onClick={() => {
                                        markLabelChange(index, -1);
                                    }} />
                                    <MarkButton label="Null" status="null" isSelected={item.label == -2} onClick={() => {
                                        markLabelChange(index, -2);
                                    }} />
                                    {
                                        index != markData.length - 1 && <MarkButton label="Merge" status="merge" isSelected={item.label == -3} onClick={() => {
                                            markLabelChange(index, -3);
                                        }} />
                                    }
                                </div>
                            </div>
                            {
                                item.label == -1 && <div className="input-wrapper">
                                    <span>Comment</span>
                                    <AutoResizeTextarea text={item.comment} onChange={(value) => {
                                        const newMarkData = [...markData];
                                        newMarkData[index].comment = value;
                                        setMarkData(newMarkData);
                                    }}></AutoResizeTextarea>
                                </div>
                            }
                        </div>
                    ))
                }

            </section>
            <section className="action">
                {
                    markData.length == 0 && <button onClick={() => {
                        autoHandleStep();
                    }}>auto step</button>
                }
                <button onClick={() => {
                    setMarkData([...markData, JSON.parse(JSON.stringify(defaultMarkData))]);
                }}>add step</button>
                <button className={`loading-button ${showSubmitLoading ? 'loading' : ''}`} onClick={() => submit()}>
                    {showSubmitLoading ? '' : 'Submit'}
                </button>
            </section>
        </StyleSubmitMark>
    );
}
export default SubmitMark;
