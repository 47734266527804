import './App.css';
import 'katex/dist/katex.min.css'
import JsonlUploader from './components/jsonlUpload';
import { getDatabase, ref, set } from 'firebase/database';
import { useEffect } from 'react';
import { initializeApp } from 'firebase/app';


function App() {

  useEffect(() => {
    const firebaseConfig = {
        apiKey: "AIzaSyBloT7U_HBj0LkXNNaNHga_bOD2_MxmICY",
        authDomain: "questlabs-meta.firebaseapp.com",
        projectId: "questlabs-meta",
        storageBucket: "questlabs-meta.appspot.com",
        messagingSenderId: "470599181197",
        appId: "1:470599181197:web:40c97633e26a875bb893c4",
        measurementId: "G-Q55XJSKGWT"
    };
    const app = initializeApp(firebaseConfig);
    getDatabase(app)
}, [])
  
  const test = async () => {
    try {
      const data = {
        name: "Jossshnss Doeee",
        age: 1215,
        email: "john@example.com"
      };
      const db = getDatabase();
      set(ref(db, 'users/' + 1), data);
      console.log("data successfully written!");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  return (
    <div className="App">
      <JsonlUploader />
    </div>
  );
}

export default App;
