import React, { useRef, useEffect, useState } from 'react';

const AutoResizeTextarea = ({text, onChange}:{text: string, onChange: (value: string) => void}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    // 在组件加载后和每次文本发生变化时，调整Textarea的高度
    adjustTextareaHeight();
  }, [text]);

  const adjustTextareaHeight = () => {
    const textarea: any = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // 先将高度设置为auto，以便计算实际高度
      textarea.style.height = `${textarea.scrollHeight}px`; // 设置为实际高度
    }
  };

  return (
    <textarea
      ref={textareaRef}
      value={text}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      style={{ resize: 'none', overflowY: 'hidden' }}
    />
  );
};

export default AutoResizeTextarea;
