import Markdown from 'react-markdown'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import styled from 'styled-components';
import { IUser } from './jsonlUpload';
import SubmitMark from './submitMark';

interface IAppMarkDownProps {
    markDownData: string;
    userInfo: IUser;
    questionIndex: number;
}

const StyleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const ArrayItem = styled.div`
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    & div{
        border: none;
    }
`

const StyleBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #ccc;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
    .answer-box{
        display: flex;
        .formatAnswer{
            display: flex;
            flex:1;
            flex-direction: column;
            align-items: flex-start;
        }
        .originAnswer{
            display: flex;
            flex:1;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            gap: 10px;
            padding: 10px 0;
            textarea{
                flex: 1;
                width: 100%;
            }
        }
    }
`

const StyleKey = styled.div`
    color: #000;
`
const StyleValue = styled.div`
    color: green;
    width: 100%;
`
function JsonToMarkdown(props: { data: any }): any {
    const { data } = props;
    if (Array.isArray(data)) {
        const arrEntries = data.map((val, index) => (
            <ArrayItem>
                {JsonToMarkdown({ data: val })}
            </ArrayItem>
        ));
        return <>
            { arrEntries }
        </>;
    } else if (typeof data === 'object' && data !== null) {
        const entries = Object.entries(data)
            .map(([key, val]) => (
                <StyleBox key={key}>
                    <div className='answer-box'>
                        <div className='formatAnswer'>
                            <StyleKey>{key}:</StyleKey>
                            <StyleValue>{JsonToMarkdown({ data: key === 'gt_answer' ? `\$${val}\$` : val })}</StyleValue>
                        </div>
                        {
                            key == 'answer' && <div className='originAnswer'>
                                <>
                                    <StyleKey>originAnswer:</StyleKey>
                                    <textarea value={val as string}></textarea>
                                </>
                            </div>
                        }
                    </div>
                </StyleBox>
            ));
        return entries;
    } else {
        return <Markdown remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
            {'' + data}
        </Markdown>

    }
}

const AppMarkDown = (props: IAppMarkDownProps) => {
    const { markDownData, userInfo, questionIndex } = props;
    const data = JSON.parse(markDownData);
    return <StyleWrapper>
        <JsonToMarkdown data={data} />
        <SubmitMark userInfo = {userInfo} questionIndex={questionIndex} originJson={data}/>
    </StyleWrapper>
}

export default AppMarkDown;