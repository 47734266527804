import React, { useState } from 'react';

interface IButtonProps {
    label: string;
    isSelected: boolean;
    status: string;
    width?: number;
    onClick: () => void;
}

const MarkButton = (props: IButtonProps) => {

  let buttonColor = '';

  switch (props.status) {
    case 'good':
      buttonColor = 'green';
      break;
    case 'neutral':
      buttonColor = 'gray';
      break;
    case 'bad':
      buttonColor = '#ff00006e';
      break;
    case 'null':
      buttonColor = 'blue';
      break;
    case 'merge':
      buttonColor = '#ff7123';
      break;  
    default:
      buttonColor = '';
  }

  const buttonStyle = {
    backgroundColor: props.isSelected ? buttonColor : 'white',
    color: props.isSelected ? 'white' : 'black',
    padding: '10px',
    margin: '5px',
    borderRadius: '12px',
    cursor: 'pointer',
    border: props.isSelected ? 'none' : `1px solid #80808069`,
    width: props.width ? `${props.width}px` : '200px',
  };

  const handleClick = () => {
    props.onClick();
  };

  return (
    <button style={buttonStyle} onClick={handleClick}>
      {props.label}
    </button>
  );
};

export default MarkButton;
